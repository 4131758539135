<template>
  <div>
    <SpinnerLogo v-if="loading" class="pt-5" />
    <div v-else>
      <TabTitle class="mb-4" action-position="right">
        <template>Manage</template>
        <template #sub-title>Add new targets, or edit and remove existing targets.</template>
        <template #actions>
          <button class="btn btn-primary" @click="modals.create = true"><i class="fa fa-plus mr-2"></i>New Target</button>
        </template>
      </TabTitle>
      <Modal id="delete" :open="modals.create" title="Create Target" @close="modals.create = false" @submit="onCreateTarget">
        <FormGroup id="name" v-model="name" label="Name" type="text" alt />
      </Modal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Modal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import SpinnerLogo from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'TargetsManage',
  components: {
    FormGroup,
    Modal,
    SpinnerLogo,
    TabTitle
  },
  data() {
    return {
      loading: false,
      modals: {
        create: false
      },
      forms: {
        create: {
          name: 'Main Emissions Target',
          type: 'emissions',
          assetId: null,
          assetGroupId: null,
          value: null
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      targets: 'target/targets'
    })
  },
  methods: {
    ...mapActions({
      createTargets: 'target/create'
    }),
    onCreateTarget() {
      this.modals.create = false;
      // this.createTargets({ name: this.name });
    }
  }
};
</script>
